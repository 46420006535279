<template>
  <v-container fluid class="fluid">
    <h1 class="text-left product-title" v-html="$t('masterdata_new_legal_entity_header')"></h1>

    <v-form ref="createLegalEntityForm" @submit.prevent="onSubmit()" id="createLegalEntityForm">
      <!--     Allgemeine Stammdaten-->
      <v-row>
        <v-col cols="12" xs="12" md="3" xl="4">
          <!-- LegalEntityType -->
          <v-select
            id="legalEntityType"
            :label="$t('masterdata_legal_entities_overview_legal_entity_form')"
            v-model="legalEntityForm.legalEntityType"
            :items="legalEntityFormOptions"
            :item-title="$getLangKey()"
            item-value="id"
            :rules="[rules.required]"
          />
          <!-- UID -->
          <v-text-field
            id="uid"
            :label="$t('masterdata_legal_entities_overview_uid')"
            v-model="legalEntityForm.uid"
            @keydown.enter.prevent
            :append-outer-icon="legalEntityForm.uid ? 'mdi-open-in-new' : ''"
            @click:append-outer="gotoUID"
          />
          <!-- UID Status -->
          <v-select
            id="uidStatus"
            :label="$t('masterdata_legal_entities_overview_uid_status')"
            v-model="legalEntityForm.uidStatus"
            :items="booleanOptions"
            item-title="text"
            item-value="status"
          />
          <!-- Rechtsform -->
          <v-select
            id="legalForm"
            :label="$t('masterdata_legal_entities_overview_legal_form')"
            v-model="legalEntityForm.legalForm"
            :items="legalFormOptions"
            :item-title="this.$getLangKey()"
            item-value="id"
            :rules="[rules.required]"
          />
          <!-- EGID -->
          <v-text-field id="egid" type="number" :label="$t('masterdata_legal_entities_overview_egid')" v-model="legalEntityForm.egid" @keydown.enter.prevent />
          <!-- AGIS-ID Person -->
          <v-text-field
            id="agisIdPerson"
            type="number"
            :label="$t('masterdata_legal_entities_overview_agis_id_person')"
            v-model="legalEntityForm.agisId"
            @keydown.enter.prevent
          />
        </v-col>
      </v-row>
      <v-btn class="m-2" color="primary" type="submit" :loading="loading">
        <span v-html="$t('masterdata_new_legal_entity_save')" />
      </v-btn>
      <v-btn class="m-2" color="secondary" :to="{ name: 'masterdata_search' }" @click="reset">
        <span v-html="$t('masterdata_new_legal_entity_back')" />
      </v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import addressPreview from '../masterdata/addressPreview'
import { mapGetters } from 'vuex'
import { useCountriesStore } from '@/store/enums/CountriesStore'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'

const legalEntitiesDataDefault = {
  legalEntityType: null,
  uid: '',
  uidStatus: false,
  legalForm: null,
  egid: '',
  agisId: ''
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'legalEntitiesCreate',
  components: { addressPreview },
  data() {
    return {
      loading: false,
      overviewData: [],
      activeTab: 0,
      legalFormOptions: [],
      legalEntityFormOptions: [],
      localities: [],
      legalEntityForm: legalEntitiesDataDefault,
      nextButtonStatus: false,
      booleanOptions: [
        {
          id: 0,
          status: false,
          text: this.$t('masterdata_new_legal_entity_false')
        },
        {
          id: 1,
          status: true,
          text: this.$t('masterdata_new_legal_entity_true')
        }
      ],
      fielddef: [
        { name: 'zip', min: 4, max: 4 },
        { name: 'locality', min: 2, max: 40 }
      ],
      rules: {
        required: (value) => !!value || this.$t('form_field_required')
      }
    }
  },
  computed: {
    countries() {
      return useCountriesStore().Countries
    },
    languages() {
      useLanguagesStore().languages
    }
  },
  methods: {
    gotoUID() {
      window.open('https://www.uid.admin.ch/Detail.aspx?uid_id=' + this.legalEntityForm.uid, '_blank')
    },
    reset() {
      this.legalEntityForm = {}
    },
    goForward() {
      this.activeTab = 1
    },
    zipRule() {
      if (typeof parseInt(this.legalEntityFormAddress.zip) != 'number') return false
      //im schweizer range
      if (this.legalEntityFormAddress.zip < 999 || this.legalEntityFormAddress.zip > 9999) return false
      //für die Plz konnte ein Ort aufgelöst werden
      return this.localities.length > 0
    },
    localityRule() {
      if (this.legalEntityFormAddress.locality.length >= this.min('locality') && this.legalEntityFormAddress.locality.length <= this.max('locality')) {
        return true
      }
      return false
    },
    async onSubmit() {
      if (!(await this.$refs.createLegalEntityForm.validate()).valid) {
        return false
      }
      try {
        this.loading = true
        const result = await this.axios.post(
          apiURL + '/legalEntities',
          {
            legalEntityType: { id: this.legalEntityForm.legalEntityType },
            uid: this.legalEntityForm.uid,
            uidStatus: this.legalEntityForm.uidStatus,
            legalForm: { id: this.legalEntityForm.legalForm },
            egid: parseInt(this.legalEntityForm.egid),
            agisId: parseInt(this.legalEntityForm.agisId),
            addresses: []
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.loading = false
        this.$router
          .push({
            name: 'masterdata_legal_entities_edit',
            params: { id: result.data.id, tab: 'overview' }
          })
          .catch((e) => {
            /* duplicated route */
          })
      } catch (e) {
        this.loading = false
        emitter.emit('toastError', {
          message: this.$t('masterdata_new_legal_entity_toast_error')
        })
        showError(e)
      }
    },
    async getLegalForms() {
      const result = await this.axios.get(apiURL + '/legalForms', {})
      this.legalFormOptions = await result.data
    },
    async getLegalEntityTypes() {
      const result = await this.axios.get(apiURL + '/legalEntityTypes', {})
      this.legalEntityFormOptions = await result.data
      this.legalEntityFormOptions = this.legalEntityFormOptions.filter((leType) => leType.id !== 1)
    }
  },
  beforeMount() {
    useCountriesStore().fetchCountries()
  },
  mounted() {
    this.getLegalForms()
    this.getLegalEntityTypes()
  }
})
</script>

<style>
.create-box {
  margin-top: 20px;
}
</style>
